import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/itcss",
  "date": "2016-05-31",
  "title": "ITCSS",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "ITCSS or Inverted Triangle CSS is a meta framework designed to address some common issues around CSS we encounter today. Introduced by Harry Roberts, it is a sane, scalable, managed architecture for CSS."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Problems with CSS at scale can be varied:`}</p>
    <ul>
      <li parentName="ul">{`Specificity`}</li>
      <li parentName="ul">{`Global namespace`}</li>
      <li parentName="ul">{`The cascade & inheritance`}</li>
      <li parentName="ul">{`The source order`}</li>
    </ul>
    <p>{`The result of all above problems will lead to redundancy in CSS code.`}</p>
    <h2>{`Specificity Wars`}</h2>
    <p>{`No matter how well organized your source code is with well considered source and cascading or even using naming conventions, specificity can break all of it.`}</p>
    <p>{`What are we trying to resolve?`}</p>
    <ul>
      <li parentName="ul">{`Reduce redundancy`}</li>
      <li parentName="ul">{`Eliminate specificity issues`}</li>
    </ul>
    <p>{`Most CSS related methodologies would try and avoid the features of CSS but ITCSS makes them work to our advantage.`}</p>
    <Message type="info" title="Compatibility" content="ITCSS is compatible with the likes of SMACSS, BEM, or OOCSS." mdxType="Message" />
    <h2>{`Default Layers`}</h2>
    <p>{`The default layers of ITCSS are as follows;`}</p>
    <ul>
      <li parentName="ul">{`Settings Global variables, config switches`}</li>
      <li parentName="ul">{`Tools Default mixins and functions`}</li>
      <li parentName="ul">{`Generic Ground-zero styles (Normalize.css, resets, box-sizing)`}</li>
      <li parentName="ul">{`Base Unclassed HTML elements (type selectors)`}</li>
      <li parentName="ul">{`Objects Cosmetic-free design patterns`}</li>
      <li parentName="ul">{`Components Designed components, chunks of UI`}</li>
      <li parentName="ul">{`Trumps Helpers and overrides`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-31-itcss/triangle.png",
        "alt": "ITCSS Triangle"
      }}></img></p>
    <p>{`Let’s look at each of the layers.`}</p>
    <h3>{`Settings`}</h3>
    <p>{`This includes:`}</p>
    <ul>
      <li parentName="ul">{`Globally-available settings.`}</li>
      <li parentName="ul">{`Config switches.`}</li>
      <li parentName="ul">{`Brand colours, etc.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$color-ui: #BADA55;
$spacing-unit: 10px;
`}</code></pre>
    <p>{`Generic`}</p>
    <p>{`This includes:`}</p>
    <ul>
      <li parentName="ul">{`Ground zero styles`}</li>
      <li parentName="ul">{`Low-specificity, far-reaching`}</li>
      <li parentName="ul">{`Resets, Normalize.css, etc`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`* {
 -webkit-box-sizing: border-box;
 -moz-box-sizing: border-box;
 box-sizing: border-box;
}
`}</code></pre>
    <h3>{`Objects`}</h3>
    <p>{`This includes:`}</p>
    <ul>
      <li parentName="ul">{`OOCSS`}</li>
      <li parentName="ul">{`Design patterns`}</li>
      <li parentName="ul">{`No cosmetics`}</li>
      <li parentName="ul">{`Begin using classes exclusively`}</li>
      <li parentName="ul">{`Agnostically named (e.g. `}<inlineCode parentName="li">{`.uilist{}`}</inlineCode>{`).`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.ui-list {
 margin: 0;
 padding: 0;
 list-style: none;
}
   .ui-list__item {
   padding: $spacing-unit;
   }
`}</code></pre>
    <h3>{`Components`}</h3>
    <p>{`This includes:`}</p>
    <ul>
      <li parentName="ul">{`Designed pieces of UI.`}</li>
      <li parentName="ul">{`Still only using classes`}</li>
      <li parentName="ul">{`More explicitly named(e.g. .products-list {}).`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.products-list {
 @include font-brand();
 border-top: 1px solid $color-ui;
}
   .products-list__item {
     border-bottom: 1px solid $color-ui;
   }
`}</code></pre>
    <h3>{`Trumps`}</h3>
    <p>{`This includes:`}</p>
    <p>{`Overrides, helpers, utilities
Only affect one piece of the DOM at a time
Usually carry !important.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.one-half {
  width: 50% !important;
}
`}</code></pre>
    <h3>{`Scaling ITCSS`}</h3>
    <p>{`Add or remove layers if, as, and when you need to.
Not using a preprocessor? Remove the Settings and Tools layers.
Don’t use OOCSS? Remove the Objects layer.
Need theming? Add a Theme layer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      